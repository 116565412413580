import * as Sentry from '@sentry/vue'
import Vue from 'vue'

const filters = []

export default function init(dsn, router) {
  const integrations = [Sentry.replayIntegration()]

  if (router) {
    integrations.push(Sentry.browserTracingIntegration({ router }))
  } else {
    integrations.push(Sentry.browserTracingIntegration())
  }

  Sentry.init({
    Vue,
    dsn: dsn,
    integrations: integrations,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/[\w-]*albatross-api.myblueraven\.com\/api/
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      'NavigationDuplicated',
      'Failed to fetch dynamically imported module',
      'Unable to preload CSS'
    ]
  })
}

export function setUserContext(user) {
  Sentry.setUser(user)
}
